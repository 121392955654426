<template>
  <div id="blog-tags" v-loading="loading">
    <h2 class="blog-tags-title">博客标签</h2>
    <div class="blog-tags-wrap">
      <div
        v-for="(tag, index) in tags"
        :key="index"
        @click="toTag(tag.tagId)"
        class="blog-tag-item">
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-tags',
  data () {
    return {
      tags: [],
      loading: true
    }
  },
  methods: {
    toTag (tagId) {
      this.$router.push({
        name: 'TagArticleList',
        params: { tagId: tagId }
      })
    },
    getTags () {
      this.$http({
        url: this.$api.BLOG_GET_TAG_COUNT_URL,
        method: 'GET'
      }).then(response => {
        if (response.status === 'success') {
          for (const item of response.data.result) {
            this.tags.push({
              tagId: item.tagId,
              name: item.name
            })
          }
          this.loading = false
        } else {
          this.$message.error('服务器好像出错了...')
          this.loading = false
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
        this.loading = false
      })
    }
  },
  created () {
    this.getTags()
  }
}
</script>

<style lang="stylus" scoped>
#blog-tags
  min-height: calc(100vh - 160px)

.blog-tags-title
  margin: 0 0 20px 15px

.blog-tags-wrap
  max-width: 600px
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-items: flex-end
  justify-content: center
  margin: 0 auto 60px auto
  .blog-tag-item
    position: relative
    padding: 8px 12px
    margin: 5px
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    color: #878787
    &:after
      content: ""
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 2px
      background-color: rgba(105, 105, 105, 0.95)
      visibility: hidden
      transform: scaleX(0)
      transition-duration: .2s
      transition-timing-function: ease
    &:hover
      &:after
        visibility: visible
        transform: scaleX(1)
        transition-duration: .2s
        transition-timing-function: ease

@keyframes show {
  from {
    margin-top: -10px;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
</style>
